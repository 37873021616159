<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import {getStaffDetail,saveMyInfo} from '@/apis/user';
import {lookup} from '@/apis/common';
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  page: {
    title: "我的档案",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,Multiselect ,ckeditor: CKEditor.component},
  data() {
    return {
      title: "我的档案",
      my:{
        signature:""
      },
      editor: ClassicEditor,
      emergencyContactRelationSelect:[],
      emergencyContactRelationList:[],
      departmentSelect:[],
      departmentList:[],
    };
  },
  created(){
    this.init();
  },
  computed:{
  },
  methods:{
    /**
     * 初始化
     */
    async init(){
      await lookup({"type":"DEPT,EC-RELATION"}).then((res)=>{
        this.emergencyContactRelationList = res.data["EC-RELATION"];
        this.departmentList = res.data["DEPT"];
      })
      getStaffDetail().then((res)=>{
        this.my = res.data
        this.emergencyContactRelationSelect =  this.emergencyContactRelationList.filter((item)=>{
          return item.value == this.my.emergencyContactRelation
        })
        this.departmentSelect =  this.departmentList.filter((item)=>{
          return item.value == this.my.department
        })
      })
    },
    handleUpdate(){
      const params = {...this.my}
      if(this.emergencyContactRelationSelect.length==1){
        params.emergencyContactRelation = this.emergencyContactRelationSelect[0].value
      }
      if(this.departmentSelect.length==1){
        params.department = this.departmentSelect[0].value
      }
      saveMyInfo(params).then(()=>{
        Swal.fire("更新成功","", "success");
      }).catch((res)=>{
        Swal.fire("", res.msg||"操作失败", "error");
      })
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
      <div class="col-xxl-3 col-lg-4">
          <div class="card">
              <div class="card-body p-0">
                  <div class="user-profile-img">
                      <img src="@/assets/images/pattern-bg.jpg" class="profile-img profile-foreground-img rounded-top" style="height: 120px;" alt="">
                      <div class="overlay-content rounded-top">
                          <div>
                              <div class="user-nav p-3">
                                  <div class="d-flex justify-content-end"> 
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- end user-profile-img -->

                  <div class="mt-n5 position-relative border-bottom p-3">
                      <div class="text-center">
                          <img src="assets/images/users/avatar-3.jpg" alt="" class="avatar-xl rounded-circle img-thumbnail">

                          <div class="mt-3">
                              <h5 class="mb-1">{{my.nickName}}</h5>
                              <div>
                                  <a href="#" class="badge badge-soft-success m-1">{{my.title}}</a>
                              </div>

                              <div class="mt-4">
                                  <a href="/change-password" class="btn btn-primary waves-effect waves-light btn-sm"><i class="bx bx-send me-1 align-middle"></i>修改密码</a>
                              </div>
                          </div>

                      </div>
                  </div>
                  <div class="p-4 mt-2">
                      <div class="mt-4">
                          <p class="text-muted mb-1">Name :</p>
                          <h5 class="font-size-14 text-truncate">{{my.staffName}}</h5>
                      </div>

                      <div class="mt-4">
                          <p class="text-muted mb-1">E-mail :</p>
                          <h5 class="font-size-14 text-truncate">{{my.email}}</h5>
                      </div>

                      <div class="mt-4">
                          <p class="text-muted mb-1">Mobile :</p>
                          <h5 class="font-size-14 text-truncate">{{my.mobile}}</h5>
                      </div>
                  </div>

              </div>
              <!-- end card body -->
          </div>
      </div>
      <!-- end col -->

      <div class="col-xxl-9 col-lg-8">
          <div class="card">
              <div class="card-body">
                  <h5 class="card-title mb-4">我的档案</h5>
                  <form>
                      <div class="card border shadow-none mb-5">
                          <div class="card-header d-flex align-items-center">
                              <div class="flex-shrink-0 me-3">
                                  <div class="avatar">
                                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                          01
                                      </div>
                                  </div>
                              </div>
                              <div class="flex-grow-1">
                                  <h5 class="card-title">基本信息</h5>
                              </div>
                          </div>
                          <div class="card-body">
                              <div>
                                  <div class="row">
                                      <div class="col-lg-6 mb-3">
                                              <label class="form-label" for="gen-info-name-input">姓名</label>
                                              <input type="text" class="form-control" id="gen-info-name-input" placeholder="请输入姓名" v-model="my.staffName" disabled>
                                      </div>
                                      <div class="col-lg-6">
                                          <div class="mb-3">
                                              <label class="form-label" for="gen-info-id-input">身份证号</label>
                                              <input type="text" class="form-control" id="gen-info-id-input" placeholder="请输入身份证号" v-model="my.idNo" disabled>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-lg-6 mb-3">
                                        <label class="form-label" for="gen-info-nickname-input">昵称</label>
                                        <input type="text" class="form-control" id="gen-info-nickname-input" placeholder="请输入昵称" v-model="my.nickName">
                                      </div>
                                      <div class="col-lg-6" mb-3>
                                          <label class="form-label d-flex" for="gen-info-sex-input">性别</label>
                                          <div class="form-check form-check-inline">
                                            <input
                                              type="radio"
                                              id="customRadioInline1"
                                              name="outer-group[0][customRadioInline1]"
                                              class="form-check-input"
                                              value="M"
                                              v-model="my.gender"
                                            />
                                            <label class="form-check-label" for="customRadioInline1"
                                              >男</label
                                            >
                                          </div>
                                          <div class="form-check form-check-inline">
                                            <input
                                              type="radio"
                                              id="customRadioInline2"
                                              name="outer-group[0][customRadioInline1]"
                                              value="F"                                        
                                              v-model="my.gender"
                                              class="form-check-input"
                                            />
                                            <label class="form-check-label" for="customRadioInline2"
                                              >女</label
                                            >
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-lg-6 mb-3">
                                        <label class="form-label" for="gen-info-department-input">部门</label>
                                        <multiselect 
                                          v-model="departmentSelect" 
                                          :options="departmentList"
                                          label="label"
                                          track-by="value"
                                          ></multiselect>
                                      </div>
                                      <div class="col-lg-6 mb-3">
                                        <label class="form-label" for="gen-info-title-input">公司职位</label>
                                      <input type="text" class="form-control" id="gen-info-title-input" placeholder="请输入职位" disabled v-model="my.title">
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end card -->

                      <div class="card border shadow-none mb-5">
                          <div class="card-header d-flex align-items-center">
                              <div class="flex-shrink-0 me-3">
                                  <div class="avatar">
                                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                          02
                                      </div>
                                  </div>
                              </div>
                              <div class="flex-grow-1">
                                  <h5 class="card-title">联系方式</h5>
                              </div>
                          </div>
                          <div class="card-body">
                              <div class="mb-3">
                                  <label for="contact-info-address-input">办公地址</label>
                                  <input type="text" class="form-control" id="contact-info-address-input" placeholder="请输入办公地址" v-model="my.companyLocation">
                              </div>
                              <div class="row">
                                  <div class="col-md-6">
                                      <div class="mb-md-0">
                                          <label for="contact-info-mobile-input" class="form-label">手机号码</label>
                                          <input type="text" class="form-control" placeholder="请输入手机号码" id="contact-info-mobile-input" v-model="my.mobile">
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="mb-md-0">
                                          <label for="contact-info-email-input" class="form-label">企业邮箱</label>
                                          <input type="email" class="form-control" id="contact-info-email-input" placeholder="请输入企业邮箱" v-model="my.email">
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                
                                <div class="col-md-12 mt-3">
                                    <label for="contact-info-email-input" class="form-label">邮件签名</label>
                                    <ckeditor v-model="my.signature" :editor="editor"></ckeditor>
                                </div>
                            </div>
                        </div>
                      </div>
                      <!-- end card -->

                      <div class="card border shadow-none">
                          <div class="card-header d-flex align-items-center">
                              <div class="flex-shrink-0 me-3">
                                  <div class="avatar">
                                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                          03
                                      </div>
                                  </div>
                              </div>
                              <div class="flex-grow-1">
                                  <h5 class="card-title">个人公司档案</h5>
                              </div>
                          </div>
                          <div class="card-body">
                              <div class="row">
                                  <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="company-private-email-input">个人邮箱</label>
                                          <input type="text" class="form-control" id="company-private-email-input" placeholder="请输入个人邮箱" v-model="my.personEmail">
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="workexperience-companyname-input">家庭地址</label>
                                          <input type="text" class="form-control" id="workexperience-companyname-input" placeholder="请输入家庭地址" v-model="my.personLocation">
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="company-private-email-input">紧急联系人</label>
                                          <input type="text" class="form-control" id="company-private-email-input" placeholder="请输入紧急联系人" v-model="my.emergencyContact">
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="workexperience-companyname-input">与紧急联系人关系</label>
                                          <multiselect 
                                          v-model="emergencyContactRelationSelect" 
                                          :options="emergencyContactRelationList"
                                          label="label"
                                          track-by="value"
                                          ></multiselect>
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="company-private-email-input">紧急联系人电话</label>
                                          <input type="text" class="form-control" id="company-private-email-input" placeholder="请输入紧急联系人电话" v-model="my.emergencyContactMobile">
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end card -->

                      <div class="text-end">
                          <button type="button" class="btn btn-primary w-sm" @click="handleUpdate">更新</button>
                      </div>
                  </form>
                  <!-- end form -->
              </div>
              <!-- end card body -->
          </div>
          <!-- end card -->
      </div>
      <!-- end col -->
  </div>
  </Layout>
</template>
<style lang="scss">
.user-profile-img{
    position: relative;
    .overlay-content{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: rgba($primary, 0.8);
        display: flex;
        height: 100%;
        color: rgba($white, 0.6);
        flex-direction: column;
    }
    .profile-img{
        width: 100%;
        height: 250px;
        object-fit: cover;
  
        @media (max-width:991px) {
            height: 160px;
        }
    }
  }

</style>